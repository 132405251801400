<template>
  <div class="body add-site">
    <div class="form">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        label-width="102px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="站点名称：" prop="name">
          <el-input type="text" v-model.trim="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所在区域：" prop="city">
          <el-cascader
            :options="cityList"
            clearable
            v-model="addForm.city"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="位置描述：" prop="locationDesc">
          <el-input
            type="text"
            v-model.trim="addForm.locationDesc"
            controls-position="right"
          ></el-input>
        </el-form-item>
        <el-form-item label="经度：" prop="longitude">
          <el-input type="text" v-model.trim="addForm.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度：" prop="latitude">
          <el-input type="text" v-model.trim="addForm.latitude"></el-input>
        </el-form-item>
      </el-form>
      <div id="container" class="definedMap"></div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import { checkLong, checkLat } from '@/common/utils/index'
import { queryAreaList } from '@/api/lib/api.js'
import {
  insertBusStation,
  updateBusStation,
  queryBusStationById
} from '@/api/lib/bus-api.js'
export default {
  props: {
    itemId: {
      type: [String, Number],
      default: () => { }
    }
  },

  data () {
    return {
      cityList: [],
      addForm: {
        name: null,
        city: [510000, 510800],
        locationDesc: null,
        longitude: null,
        latitude: null,
      },
      rules: {
        name: [{ required: true, message: "请输入车场名称", trigger: "blur" }],
        city: [{ required: true, type: 'array', message: "请选择所在区域", trigger: "change" }],
        locationDesc: [{ required: true, message: "请输入位置描述", trigger: "blur" }],
        longitude: [{ required: true, message: "请输入经度", trigger: "blur" }, { validator: checkLong, trigger: 'blur' }],
        latitude: [{ required: true, message: "请输入纬度", trigger: "blur" }, { validator: checkLat, trigger: 'blur' }],
      },
      map:null,
      marker:null,
    };
  },

  methods: {
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            name: this.addForm.name,
            city: this.addForm.city,
            locationDesc: this.addForm.locationDesc,
            longitude: this.addForm.longitude,
            latitude: this.addForm.latitude
          }
          if (!this.isEdit) {
            insertBusStation(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            updateBusStation(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    loadmap() {
      let self = this;
      let map = new AMap.Map("container", {
        mapStyle: "amap://styles/fresh",
        zoom: 11,
        resizeEnable: true,
        expandZoomRange: true,
        features: ["bg", "road", "building", "point"],
      });
      map.on('click',function(e){
        self.addForm.longitude = e.lnglat?.getLng();
        self.addForm.latitude = e.lnglat?.getLat();
        if(self.marker){
            self.marker.setMap(null);
            self.marker = null;
        }
        self.marker = new AMap.Marker({
          position: [e.lnglat.getLng(), e.lnglat.getLat()],
          offset: new AMap.Pixel(-13, -30),
          size: new AMap.Size(8, 8), // 图标尺寸
          imageSize:new AMap.Size(8, 8), // 图标尺寸
        })
        self.marker.setMap(map);
      })
      if(this.itemId){
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(this.addForm.longitude,this.addForm.latitude),
          offset: new AMap.Pixel(-13, -30),
          size: new AMap.Size(8, 8), // 图标尺寸
          imageSize:new AMap.Size(8, 8), // 图标尺寸
        })
        this.marker.setMap(map);
      }
      self.map = map;
      self.map.setFitView(self.marker)
    },
  },

  created () {
    this.getAreaList();
  },
  mounted () {
    if (this.itemId) {
      queryBusStationById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }

          delete this.addForm.createTime
          delete this.addForm.updateTime
        } else {
          this.$message.error(res.msg)
        }
        this.loadmap();
      })
      this.isEdit = true
    } else {
      this.isEdit = false
      this.loadmap();
    }
  }
};
</script>

<style lang="scss" scoped>
.add-site {
  @include themify() {
    .form{
      display: flex;
    }
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin-bottom: 10px;
    }
    #container {
        width: 100%;
        height:40vh;
      }
  }
}
</style>
